import { BaseEntityRepository } from '@repositories/BaseEntityRepository'
import { JobStatistics } from '@models/domain/JobStatistics'

export class JobStatisticsRepository extends BaseEntityRepository<JobStatistics> {
    public resourceIdentifier = 'jobStatistics'
    public modelConstructor = (data?: object) => new JobStatistics(data)

    public getJobStatistics = async () => {
        const response = await this.connection.get({
            path: `/statistics/jobs`,
        })

        return (response.data as { result: object[] }).result.map(data => new JobStatistics(data))
    }
}
