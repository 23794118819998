import { Default as DefaultTemplate } from '@templates/Default'
import React, { useContext, useEffect, useState } from 'react'
import { AuthContext } from '@services/Auth'
import { Panel } from '@tblg/components'
import { TokenDisplay } from '@atoms/TokenDisplay'
import { withTranslation, WithTranslation } from 'next-i18next'
import { POLICIES } from '@constants/policies'
import { LeaseObjectStatisticsRepository } from '@repositories/LeaseObjectStatisticsRepository'
import { ConnectionContext } from '@services/Connection'
import { LeaseObjectStatistics } from '@models/domain/LeaseObjectStatistics'
import styled from 'styled-components'
import { ScrollXTable } from '@molecules/ScrollXTable'
import capitalize from '@helpers/capitalize'
import { SupplyStatistics } from '@models/domain/SupplyStatistics'
import { SupplyStatisticsRepository } from '@repositories/SupplyStatisticsRepository'
import { FlexColumn } from '@atoms/FlexColumn'
import { ColumnWidth } from '@atoms/FlexColumn/Enums'
import { FlexRow } from '@atoms/FlexRow'
import { StatisticsPanels } from '@organisms/dashboard'

const CPanel = styled(Panel)`
  margin-bottom: 1rem;
`

const StyledUl = styled.ul`
  margin: 0;
  padding: 0;
`

const Dashboard = ({ t }: WithTranslation) => {
    const auth = useContext(AuthContext)
    const title = 'Dashboard'
    const connection = useContext(ConnectionContext)
    const leaseObjectStatisticsRepository = new LeaseObjectStatisticsRepository(connection)
    const supplyStatisticsRepository = new SupplyStatisticsRepository(connection)

    const [leaseObjectStatistics, setLeaseObjectStatistics] = useState<LeaseObjectStatistics[]>()
    const [supplyStatistics, setSupplyStatistics] = useState<SupplyStatistics[]>()

    useEffect(() => {
        const load = async () => {
            const leaseObjectResult = await leaseObjectStatisticsRepository.getLeaseObjectStatistics()
            setLeaseObjectStatistics(leaseObjectResult)
        }

        if (auth.can(POLICIES.VIEW_LEASE_OBJECTS)) {
            load()
        }
    }, [])

    useEffect(() => {
        const load = async () => {
            const supplyResult = await supplyStatisticsRepository.getSupplyStatistics()
            setSupplyStatistics(supplyResult)
        }

        if (auth.can(POLICIES.VIEW_API_LOG)) {
            load()
        }
    }, [])

    const leaseObjectcolumns = [
        {   identifier: 'type',
            header: capitalize(t('type')),
            cellContent: (row: object) => {
                const stats = row as LeaseObjectStatistics
                return `${stats.type} ${stats.carType}`
            },
        },
        {   identifier: 'nrLeaseObjects',
            header: capitalize(t('nr_lease_objects')),
        },
        {
            identifier: 'nrCalculableLeaseObjects',
            header: capitalize(t('nr_calculable_lease_objects')),
        },
        {
            identifier: 'nrPublishableLeaseObjects',
            header: capitalize(t('nr_publishable_lease_objects')),
        },
        {
            identifier: 'nrCalculableAndPublishableLeaseObjects',
            header: capitalize(t('nr_calculable_and_publishable_lease_objects')),
        },
        {
            identifier: 'nrLeaseObjectsInElastic',
            header: capitalize(t('nr_lease_objects_in_elastic')),
            cellContent: (row: object) => {
                const stats = row as LeaseObjectStatistics
                const keys = Object.keys(stats.nrLeaseObjectsInElastic!)

                if (keys.length === 0) {
                    return '-'
                }

                if (keys.length === 1) {
                    return stats.nrLeaseObjectsInElastic![keys[0]]
                }

                return (
                    <StyledUl>
                        { keys.map(target => (
                            <li key={ target }>
                                { t(`common:target.${target}`) }: { stats.nrLeaseObjectsInElastic![target] as number }
                            </li>
                        )) }
                    </StyledUl>
                )
            },
        },
    ]

    const supplyColumns = [
        {   identifier: 'client',
            header: capitalize(t('client')),
        },
        {
            identifier: '200',
            header: '200',
        },
        {
            identifier: '201',
            header: '201',
        },
        {
            identifier: '400',
            header: '400',
        },
        {
            identifier: '404',
            header: '404',
        },
        {
            identifier: '500',
            header: '500',
        },
    ]

    return (
        <DefaultTemplate
            pageTitle={ title }
        >

        { auth.can(POLICIES.DEVELOPMENT) && (
            <CPanel heading={ title }>
                <div>
                    <TokenDisplay label="Access token" token={ auth.accessToken } />
                    <TokenDisplay label="ID token" token={ auth.idToken } />
                </div>
            </CPanel>
        ) }

        { leaseObjectStatistics && (
            <CPanel heading={ capitalize(t('common:leaseobjects')) }>
                <ScrollXTable
                    columns={ leaseObjectcolumns }
                    rows={ leaseObjectStatistics }
                />
            </CPanel>
        ) }

        { auth.can(POLICIES.JOBS_VIEW) && (
            <StatisticsPanels/>
        ) }

        { supplyStatistics && (
            <FlexRow>
                <FlexColumn columnWidth={ ColumnWidth['1/2'] }>
                    <CPanel heading={ capitalize(t('supply_endpoint')) }>
                        <ScrollXTable
                            columns={ supplyColumns }
                            rows={ supplyStatistics }
                        />
                    </CPanel>
                </FlexColumn>
            </FlexRow>
        ) }

        </DefaultTemplate>
    )
}

export default withTranslation('dashboard')(Dashboard)
