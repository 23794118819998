import { Heading, HeadingType } from '@tblg/components'
import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  padding: 10px 0 10px 0;
`

const Pre = styled.pre`
  cursor: pointer;
  background-color: ${props => props.theme.colors.quaternary};
  color: ${props => props.theme.colors.white};
  border-radius: 4px;
  width: 100%;

  &:active {
    background-color: ${props => props.theme.colors.quinary};
  }
`

const Text = styled.span`
  width: 96%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 10px;
  display: inline-block;
  pointer-events: none;
`

interface Props {
  token: string | undefined
  label: string
}

const copyToClipboard = (value: string) => {
  const el = document.createElement('textarea')
  el.value = value
  document.body.appendChild(el)
  el.select()
  document.execCommand('copy')
  document.body.removeChild(el)
}

export const TokenDisplay = ({ token, label }: Props) => {
  const onClick = () => {
    if (token) {
      copyToClipboard(token)
    }
  }

  return (
    <Container>
      <Heading type={ HeadingType.h6 }>{ label }</Heading>
      <Pre title="Copy" onClick={ onClick }>
        <Text>{ token }</Text>
      </Pre>
    </Container>
  )
}
