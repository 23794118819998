import { BaseEntityRepository } from '@repositories/BaseEntityRepository'
import { LeaseObjectStatistics } from '@models/domain/LeaseObjectStatistics'

export class LeaseObjectStatisticsRepository extends BaseEntityRepository<LeaseObjectStatistics> {
    public resourceIdentifier = 'leaseObjectStatistics'
    public modelConstructor = (data?: object) => new LeaseObjectStatistics(data)

    public getLeaseObjectStatistics = async (): Promise<LeaseObjectStatistics[]> => {
        const response = await this.connection.get({
            path: `/statistics/lease-objects`,
        })

        return (response.data as { result: object[] }).result.map(data => new LeaseObjectStatistics(data))
    }
}
