import { Connection } from '@services/Connection/Interfaces'
import { DomainModel } from '@models/domain/interfaces/DomainModel'
import { Entity } from '@models/domain/interfaces/Entity'
import { Type } from '@models/domain/interfaces/Attribute'
import { EntityRepositoryInterface } from '@repositories/interfaces/EntityRepository'
import { Props } from './Props'
import { LeaseObjectStatisticsRepository } from '@repositories/LeaseObjectStatisticsRepository'

export class LeaseObjectStatistics extends DomainModel<LeaseObjectStatistics> implements Entity {
    public readonly classIdentifier = 'LeaseObjectStatistics'
    public readonly localeNamespace = 'lease_object_statistics'

    public readonly attributes = [
        {
            key: 'type',
            type: Type.STRING,
        },
        {
            key: 'carType',
            type: Type.STRING,
        },
        {
            key: 'nrLeaseObjects',
            type: Type.INTEGER,
        },
        {
            key: 'nrCalculableLeaseObjects',
            type: Type.INTEGER,
        },
        {
            key: 'nrPublishableLeaseObjects',
            type: Type.INTEGER,
        },
        {
            key: 'nrCalculableAndPublishableLeaseObjects',
            type: Type.INTEGER,
        },
        {
            key: 'nrLeaseObjectsInElastic',
            type: Type.JSON,
        },
    ]

    public type?: string
    public carType?: string
    public nrLeaseObjects?: number
    public nrCalculableLeaseObjects?: number
    public nrPublishableLeaseObjects?: number
    public nrCalculableAndPublishableLeaseObjects?: number
    public nrLeaseObjectsInElastic?: Record<string, number>

    constructor(props?: Props) {
        super()
        Object.assign(this, props)
    }

    public readonly repository: (connection: Connection) => EntityRepositoryInterface<LeaseObjectStatistics>
        =  (connection: Connection) => new LeaseObjectStatisticsRepository(connection)

}
