import styled from 'styled-components'
import { WithThemeProp } from '@tblg/components'
import ScrollContainer from 'react-indiana-drag-scroll'

export const TableWrapper = styled(ScrollContainer)<WithThemeProp>`
  &::-webkit-scrollbar {
    background-color: ${ props => props.theme.colors.lightBlue };
    height: 5px;
  }
  
  &::-webkit-scrollbar-thumb {
    background-color: ${ props => props.theme.colors.quinary };
  }
`
