import React, { FC, useContext, useEffect, useState } from 'react'
import { WithT } from 'i18next'
import { withTranslation } from 'next-i18next'
import { FlexColumn } from '@atoms/FlexColumn'
import { FlexRow } from '@atoms/FlexRow'
import { ColumnWidth } from '@atoms/FlexColumn/Enums'
import { JobStatistics } from '@organisms/dashboard/JobStatistics'
import styled from 'styled-components'
import { Panel } from '@tblg/components'
import { ConnectionContext } from '@services/Connection'
import { JobStatisticsRepository } from '@repositories/JobStatisticsRepository'
import { JobStatistics as JSEntity } from '@models/domain/JobStatistics'
import { AutotelexJobs, DatastorageJobs, JatoJobs } from '@models/domain/JobStatistics/Enums'
import capitalize from '@helpers/capitalize'

const CPanel = styled(Panel)`
  margin-bottom: 1rem;
`

const StatisticsPanelsInner: FC<WithT> = ({t}) => {

    const connection = useContext(ConnectionContext)
    const jobStatisticsRepository = new JobStatisticsRepository(connection)

    const [jatoStatistics, setJatoStatistics] = useState<JSEntity[]>()
    const [autotelexStatistics, setAutotelexStatistics] = useState<JSEntity[]>()
    const [datastorageStatistics, setDatastorageStatistics] = useState<JSEntity[]>()

    useEffect(() => {
        const load = async () => {
            const jobResult = await jobStatisticsRepository.getJobStatistics()
            setJatoStatistics(jobResult.filter((stat: JSEntity) =>
                Object.values(JatoJobs).includes(stat.name)))
            setAutotelexStatistics(jobResult.filter((stat: JSEntity) =>
                Object.values(AutotelexJobs).includes(stat.name)))
            setDatastorageStatistics(jobResult.filter((stat: JSEntity) =>
                Object.values(DatastorageJobs).includes(stat.name)))
        }
        load()
    }, [])

    return (
        <FlexRow>
            <FlexColumn columnWidth={ ColumnWidth['1/3'] }>
                <CPanel heading={ capitalize(t('common:jato')) }>
                    <JobStatistics
                        statistics={ jatoStatistics ?? [] }
                    />
                </CPanel>
            </FlexColumn>
            <FlexColumn columnWidth={ ColumnWidth['1/3'] }>
                <CPanel heading={ capitalize(t('common:autotelex')) }>
                    <JobStatistics
                        statistics={ autotelexStatistics ?? [] }
                    />
                </CPanel>
            </FlexColumn>
            <FlexColumn columnWidth={ ColumnWidth['1/3'] }>
                <CPanel heading={ capitalize(t('common:datastorage')) }>
                    <JobStatistics
                        statistics={ datastorageStatistics ?? [] }
                    />
                </CPanel>
            </FlexColumn>
        </FlexRow>
    )
}

export const StatisticsPanels = withTranslation('dashboard')(StatisticsPanelsInner)
