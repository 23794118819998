export { JustifyContent } from '@atoms/FlexColumn/Enums'

export enum RowHeight {
    'FULL_HEIGHT' = '100%',
    '1/2' = '50%',
    '1/3' = '33.33333%',
    '1/4' = '25%',
    '1/5' = '20%',
    '1/6' = '16.66666%',
    '3/4' = '75%',
}

export enum Gutter {
    'NONE' = 0,
    'DEFAULT' = 1,
    'SMALL' = 0.5,
    'LARGE' = 2,
}

export enum AlignItems {
    'FLEX_START' = 'flex-start',
    'FLEX_END' = 'flex-end',
    'CENTER' = 'center',
    'STRETCH' = 'stretch',
    'BASELINE' = 'baseline',
}

export enum Flow {
    'NO_WRAP' = 'nowrap',
    'WRAP' = 'wrap',
}
