export enum ColumnWidth {
    'AUTO' = 'auto',
    'FULL_WIDTH' = '100%',
    '1/2' = '50%',
    '1/3' = '33.33333%',
    '1/4' = '25%',
    '1/5' = '20%',
    '1/6' = '16.66666%',
    '3/4' = '75%',
    '2/3' = '66.666666%',
}

export enum JustifyContent {
    'START' = 'start',
    'CENTER' = 'center',
    'SPACE_BETWEEN' = 'space-between',
    'SPACE_AROUND' = 'space-around',
    'SPACE_EVENLY' = 'space-evenly',
}

export enum TextAlign {
    'LEFT' = 'left',
    'CENTER' = 'center',
    'RIGHT' = 'right',
}
