export enum JatoJobs {
    'JATO_LOAD' = 'calculator-jato-load-v1',
    'JATO_IMPORT' = 'Admin API - Jato importer',
}

export enum AutotelexJobs {
    'AUTOTELEX_LOAD' = 'calculator-autotelex-load-v1',
    'AUTOTELEX_IMPORT' = 'admin-api-autotelex-importer',
}

export enum DatastorageJobs {
    'ADMIN_API_SYNC_LEASE_OBJECTS_TO_ELASTIC' = 'admin-api-sync-leaseobjects-to-elastic',
}
