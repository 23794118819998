import styled from 'styled-components'
import { Props } from './Props'
import { ColumnWidth } from './Enums'

export const FlexColumn = styled.div<Props>`
  width: ${ props => props.columnWidth ? props.columnWidth : ColumnWidth.FULL_WIDTH };
  max-width: ${ props => props.columnWidth ? props.columnWidth : ColumnWidth.FULL_WIDTH };
  ${ props => props.justifyContent && `justify-content: ${props.justifyContent};` }
  ${ props => props.textAlign && `text-align: ${props.textAlign};` }
  ${ props => props.flexGrow && `flex-grow: ${props.flexGrow};` }
 
`
