import { DomainModel } from '@models/domain/interfaces/DomainModel'
import { Entity } from '@models/domain/interfaces/Entity'
import { Type } from '@models/domain/interfaces/Attribute'
import { Props } from './Props'
import { Connection } from '@services/Connection/Interfaces'
import { EntityRepositoryInterface } from '@repositories/interfaces/EntityRepository'
import { JobStatisticsRepository } from '@repositories/JobStatisticsRepository'

export class JobStatistics extends DomainModel<JobStatistics> implements Entity {
    public readonly classIdentifier = 'JobStatistics'
    public readonly localeNamespace = 'job_statistics'

    public readonly attributes = [
        {
            key: 'name',
            type: Type.STRING,
        },
        {
            key: 'startDate',
            type: Type.DATE,
        },
        {
            key: 'status',
            type: Type.INTEGER,
        },
        {
            key: 'progress',
            type: Type.STRING,
        },
        {
            key: 'lastSuccessfulStartDate',
            type: Type.DATE,
        },
    ]

    constructor(props?: Props) {
        super()
        Object.assign(this, props)
    }

    public readonly repository: (connection: Connection) => EntityRepositoryInterface<JobStatistics>
        =  (connection: Connection) => new JobStatisticsRepository(connection)
}
