import { BaseEntityRepository } from '@repositories/BaseEntityRepository'
import { SupplyStatistics } from '@models/domain/SupplyStatistics'

export class SupplyStatisticsRepository extends BaseEntityRepository<SupplyStatistics> {
    public resourceIdentifier = 'supplyStatistics'
    public modelConstructor = (data?: object) => new SupplyStatistics(data)

    public getSupplyStatistics = async () => {
        const response = await this.connection.get({
            path: `/statistics/supply`,
        })

        return (response.data as { result: object[] }).result.map(data => new SupplyStatistics(data))
    }
}
