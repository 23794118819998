import { DomainModel } from '@models/domain/interfaces/DomainModel'
import { Entity } from '@models/domain/interfaces/Entity'
import { Type } from '@models/domain/interfaces/Attribute'
import { Props } from '@models/domain/Make/Props'
import { Connection } from '@services/Connection/Interfaces'
import { EntityRepositoryInterface } from '@repositories/interfaces/EntityRepository'
import { SupplyStatisticsRepository } from '@repositories/SupplyStatisticsRepository'

export class SupplyStatistics extends DomainModel<SupplyStatistics> implements Entity {
    public readonly classIdentifier = 'SupplyStatistics'
    public readonly localeNamespace = 'supply_statistics'

    public readonly attributes = [
        {
            key: 'client',
            type: Type.STRING,
        },
        {
            key: '200',
            type: Type.INTEGER,
        },
        {
            key: '201',
            type: Type.INTEGER,
        },
        {
            key: '400',
            type: Type.INTEGER,
        },
        {
            key: '401',
            type: Type.INTEGER,
        },
        {
            key: '404',
            type: Type.INTEGER,
        },
        {
            key: '500',
            type: Type.INTEGER,
        },
    ]

    constructor(props?: Props) {
        super()
        Object.assign(this, props)
    }

    public readonly repository: (connection: Connection) => EntityRepositoryInterface<SupplyStatistics>
        =  (connection: Connection) => new SupplyStatisticsRepository(connection)
}
