import React, { FC } from 'react'
import { JobStatistics as JSEntity } from '@models/domain/JobStatistics'
import capitalize from '@helpers/capitalize'
import moment from 'moment'
import { ScrollXTable } from '@molecules/ScrollXTable'
import { WithT } from 'i18next'
import { withTranslation } from 'next-i18next'

interface Props {
    statistics: JSEntity[]
}

const JobStatisticsInner: FC<Props & WithT> = ({t, statistics}) => {

    const jobColumns = [
        {   identifier: 'name',
            header: capitalize(t('name')),
        },
        {   identifier: 'startDate',
            header: capitalize(t('started')),
            cellContent: (row: object) => {
                const stats = row as JSEntity
                return moment().diff(moment(stats.startDate), 'hours') + ' H'
            },
        },
        {
            identifier: 'status',
            header: capitalize(t('status')),
        },
        {   identifier: 'lastSuccessfulStartDate',
            header: capitalize(t('success')),
            cellContent: (row: object) => {
                const stats = row as JSEntity
                return moment().diff(moment(stats.lastSuccessfulStartDate), 'hours') + ' H'
            },
        },
    ]

    return statistics ? (
        <ScrollXTable
            columns={ jobColumns }
            rows={ statistics }
        />
    ) : <></>
}

export const JobStatistics = withTranslation('dashboard')(JobStatisticsInner)
