import styled, { css } from 'styled-components'
import { Props } from './Props'
import { Gutter } from './Enums'

export const FlexRow = styled.div<Props>`
  display: flex;
  ${ props => props.rowHeight && `height: ${props.rowHeight};` }
  ${ props => props.alignItems && `align-items: ${props.alignItems};` }
  ${ props => props.justifyContent && `justify-content: ${props.justifyContent};` }
  ${ props => props.flow && `flex-flow: ${props.flow};` }
  ${ ({ gutter = Gutter.DEFAULT}: Props) => css`
      margin-left: -${gutter}rem;
      margin-right: -${gutter}rem;
      
      > * {
        padding-left: ${gutter}rem;
        padding-right: ${gutter}rem;
        box-sizing: border-box;
      }
    `
  }
`
